<template>
  <article class="container">
    <return-key class="return-key-custom" @close="handleBack"></return-key>
    <div class="introduction-containerin">
      <h1><span style="border-bottom: 2px solid #2c4eb0;">企业简介</span></h1>
      <div class="troduction">
        <div class="introduction-left">
          {{ formData.enterDetails }}
        </div>
        <el-divider direction="vertical"></el-divider>
        <!-- 轮播 -->
        <div class="introduction-right" style="max-height: 100%;display: flex;
    justify-content: center;">
          <swiper-img style="height:100%" :form-data="formData"></swiper-img>

        </div>
      </div>
    </div>
    <div class="introduction-containerin">
      <h1><span style="border-bottom: 2px solid #2c4eb0;">工商信息</span></h1>
      <div class="troduction">
        <div class="introduction-left">
          <el-descriptions class="margin-top" :column="1" border>
            <el-descriptions-item>
              <template slot="label"> 公司名称</template>{{ formData.enterName }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 法定代表人</template>{{ formData.legalPerson }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 成立日期</template>{{ formData.foundTime }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 企业类型</template>{{ formatterData(dictObj.enter_type,formData.enterType) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 所属行业</template>{{ formatField(formData.enterField) }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 公司地址</template> {{ formData.enterAddress }}
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label"> 公司官网</template>
              <a :href="formData.enterWebsite!=null&&formData.enterWebsite.indexOf('https://') != -1 ?formData.enterWebsite:'https://'+formData.enterWebsite" target="_blank">{{ formData.enterWebsite }}</a>
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div style="width: 1px;margin: 0 8px;"></div>
        <div class="introduction-right">
          <h1>经营范围</h1>
          <span class="text-format">
            {{ formData.mainProjects }}
          </span>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import SwiperImg from './swiperImg.vue'
export default {
  components: {
    SwiperImg
  },
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      formData: {},
      dictObj: {
        enter_industry: {}
      },
      swiperOptions1: {},
      swiperOptions2: {}
    }
  },
  created() {
    this.requestData()
    this.getDictObj()
  },
  mounted() {

  },
  methods: {
    requestData() {
      this.$get(this.urls.getEnterpriseInfo, null, [this.id]).then(result => {
        this.formData = result.data
      })
    },
    // 获取字典数据
    getDictObj() {
      this.$get(`${this.urls.dicValues}`, {
        types: 'enter_industry,enter_type'
      }).then((result) => {
        if (result.code === 'SUCCESS') {
          this.dictObj.enter_industry = result.data.enter_industry
          this.dictObj.enter_type = result.data.enter_type
        }
      }).catch((e) => {
      })
    },
    formatField(row) {
      var data = []
      if (row != null) {
        const list = row.split(',')
        list.forEach(element1 => {
          if (this.dictObj.enter_industry.length > 0) {
            this.dictObj.enter_industry.forEach(element2 => {
              if (element1 === element2.dictCode) {
                data.push(element2.dictValue)
              }
            })
          }
        })
      }
      return data.join(',')
    },
    formatterData(list, value) {
      if (!list || !value) {
        return value
      }
      const match = list.find(item => {
        if (item.dictCode === value) {
          return true
        }
      })
      return match ? match.dictValue : ''
    },
    handleBack() {
      this.$emit('close')
    }

  }
}
</script>
<style lang="scss" scoped>
.return-key-custom {
  position: fixed;
  left: 20px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.container {
  margin: 0;
  padding: 0;
  background-color: #eee;
  position: relative;
  .introduction-containerin {
    padding: 0 8%;
    h1 {
      padding: 26px 0;
    }
    .troduction {
      background-color: white;
      border-radius: 8px;
      display: flex;
      .introduction-left {
        padding: 20px;
        width: 50%;
        font-family: helvetica，arial，sans-serif;
        line-height: 32px;
        letter-spacing: 2px;
        font-size: 16px;
        text-indent: 32px;
        color: #606266;
      }
      .introduction-left::before {
        content: "";
        width: 5px;
        height: 50px;
        background: red;
      }
      .introduction-right {
        padding: 0 20px;
        width: 50%;
      }
    }
  }
}

.text-format {
  display: block;
  font-family: helvetica，arial，sans-serif;
  line-height: 32px;
  letter-spacing: 2px;
  font-size: 16px;
  text-indent: 32px;
  color: #606266;
}
::v-deep .el-descriptions-item__label {
  width: 150px;
}
::v-deep .el-divider--vertical {
  display: inline-block;
  width: 1px;
  height: 80%;
  margin: 38px 8px;
  vertical-align: middle;
  position: relative;
}
::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 12px;
  font-size: 0.833vw;
  font-family: helvetica，arial，sans-serif;
}
::v-deep .el-descriptions .is-bordered .el-descriptions-item__cell {
  border: 1px solid #ebeef5;
  padding: 12px 10px;
  font-size: 0.833vw;
  font-family: helvetica，arial，sans-serif;
}
</style>

